<template>
  <div class="
      min-h-screen
      flex
      items-center
      justify-center
      py-12
      px-4
      sm:px-6
      lg:px-8
    ">
    <div class="max-w-md w-full space-y-8">
      <div>
        <router-link :to="{ name: 'home' }">
          <h1 class="
              text-center text-gray-800
              hover:text-gray-700
              font-extrabold
              text-2xl
            " style="font-family: 'Gluten', cursive">
            putzfome
          </h1>
        </router-link>

        <h2 class="
            mt-6
            text-center text-lg
            md:text-3xl
            font-extrabold
            text-gray-900
          ">
          Crie sua conta
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          já é registrado?
          <router-link :to="{ name: 'login' }" class="font-medium text-indigo-600 hover:text-indigo-500">
            Faça login
          </router-link>
        </p>
      </div>
      <form @submit.prevent="registerNewClient" class="mt-8 space-y-6">
        <div class="rounded-md shadow-sm -space-y-px">
          <div class="pb-6">
            <label for="name" class="sr-only">Nome</label>
            <input id="name" name="name" v-model="formData.name" type="name" autocomplete="name" class="
                appearance-none
                rounded
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
              " :class="{ 'border border-red-600 ': errors.name }" placeholder="Nome" />
            <div v-if="errors.name != ''" class="text-red-700 text-xs py-1">
              {{ errors.name[0] || "" }}
            </div>
          </div>
          <div class="pb-6">
            <label for="email-address" class="sr-only">Email</label>
            <input id="email-address" name="email" v-model="formData.email" type="email" autocomplete="email" class="
                appearance-none
                rounded
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
              " :class="{ 'border border-red-600 ': errors.email }" placeholder="E-mail" />
            <div v-if="errors.email != ''" class="text-red-700 text-xs py-1">
              {{ errors.email[0] || "" }}
            </div>
          </div>
          <div class="pb-6">
            <label for="telephone" class="sr-only">Celular</label>
            <the-mask :mask="['(0##) #####-####']" id="telephone" name="telephone" v-model="formData.telephone"
              type="telephone" autocomplete="telephone" class="
                appearance-none
                rounded
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
              " :class="{ 'border border-red-600 ': errors.telephone }" placeholder="Celular" />
            <div v-if="errors.telephone != ''" class="text-red-700 text-xs py-1">
              {{ errors.telephone[0] || "" }}
            </div>
          </div>

          <div class="pb-6">
            <label for="password" class="sr-only">Senha</label>
            <input id="password" name="password" v-model="formData.password" type="password"
              autocomplete="current-password" class="
                appearance-none
                rounded
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
              " :class="{ 'border border-red-600 ': errors.password }" placeholder="Senha" />
            <div v-if="errors.password != ''" class="text-red-700 text-xs py-1">
              {{ errors.password[0] || "" }}
            </div>
          </div>

        </div>

        <div>
          <button type="submit" class="
              group
              relative
              w-full
              flex
              justify-center
              py-2
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-md
              text-white
              bg-indigo-600
              hover:bg-indigo-700
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            " :disabled="loading">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: solid/lock-closed -->
              <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd" />
              </svg>
            </span>
            <span v-if="loading" class="flex items-center">
              <img src="@/assets/images/preloader.gif" alt="" class="w-5 h-5 mr-2" />
              Cadastrando
            </span>
            <span v-else>Registre-se</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      loading: false,

      formData: {
        name: "",
        email: "",
        telephone: "",
        password: "",
      },
      errors: {
        name: "",
        email: "",
        telephone: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions(["register", "login"]),
    registerNewClient() {
      this.reset();
      this.loading = true;

      const params = {
        device_name: this.deviceName,
        ...this.formData,
      };

      this.register(params)
        .then((response) => {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
            icon: "success",
            title: "Cadastro realizado com sucesso",
          });


          const company = this.$store.state.companies.companySelected;
          this.$router.push({
            name: 'products',
            params: { companyUrl: company.url, uuid: company.uuid },
          });
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = Object.assign(
              this.errors,
              error.response.data.errors
            );

            this.$swal.fire({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
              icon: "error",
              title: "Dados invalídos para registrar-se",
            });

            return;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      this.errors = {
        name: "",
        email: "",
        telephone: "",
        password: "",
      };
    },
  },
  computed: {
    deviceName() {
      return (
        navigator.appCodeName +
        navigator.appName +
        navigator.platform +
        this.formData.email
      );
    },

  },
};
</script>